@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/grid.module';
@use '~/ui/styles/color.module';

.root {
  display: grid;
  grid-template-areas:
    'preheader preheader preheader preheader preheader'
    'spacer spacer spacer spacer spacer'
    'eyebrow eyebrow eyebrow eyebrow eyebrow'
    'text text text text text'
    'credits credits credits credits credits'
    '. quickLinks quickLinks quickLinks quickLinks'
    'image image image image image';
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  &.isLandingPage {
    margin-bottom: 8.8rem;

    @media (min-width: breakpoints.$md) {
      margin-bottom: 12rem;
    }

    @media (min-width: breakpoints.$lg) {
      margin-bottom: 16rem;
    }
  }

  &:not(.isLandingPage) {
    margin-bottom: 4.8rem;

    @media (min-width: breakpoints.$md) {
      margin-bottom: 6.4rem;
    }

    @media (min-width: breakpoints.$lg) {
      margin-bottom: 8.8rem;
    }
  }

  @media (min-width: breakpoints.$md) {
    grid-template-areas:
      'preheader preheader preheader preheader preheader preheader preheader'
      'spacer spacer spacer spacer spacer spacer spacer'
      'eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow .'
      'text text text text text quickLinks quickLinks'
      'credits credits credits credits credits quickLinks quickLinks'
      'image image image image image image image';
    grid-template-columns: repeat(7, 1fr);

    &.portraitImage {
      grid-template-areas:
        'preheader preheader preheader preheader preheader preheader preheader'
        'spacer spacer spacer spacer spacer spacer spacer'
        'eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow .'
        'text text text text text quickLinks quickLinks'
        'credits credits credits image image image image';
    }
  }

  @media (min-width: breakpoints.$lg) {
    grid-template-areas:
      '. preheader preheader preheader preheader preheader preheader preheader preheader'
      '. spacer spacer spacer spacer spacer spacer spacer spacer'
      '. eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow . .'
      '. text text text text text text quickLinks quickLinks'
      '. credits credits credits credits credits credits quickLinks quickLinks'
      'image image image image image image image image image';
    grid-template-columns: repeat(9, 1fr);

    &.portraitImage {
      grid-template-areas:
        '. preheader preheader preheader preheader preheader preheader preheader preheader'
        '. spacer spacer spacer spacer spacer spacer spacer spacer'
        '. eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow . .'
        '. text text text text text text quickLinks quickLinks'
        '. credits credits credits image image image image image';
    }
  }
}

.preheader {
  grid-area: preheader;
}

.spacer {
  grid-area: spacer;
  height: 8.8rem;

  @media (min-width: breakpoints.$md) {
    height: 12rem;
  }

  @media (min-width: breakpoints.$lg) {
    // firefox doesn't support :has so we give 1em as the fallback offset
    height: calc(
      var(--nav-wordmark-spacing) + var(--primary-navigation-height) - var(--offset, 1em)
    );

    .root:has(.eyebrow) & {
      --offset: 1lh;
    }

    .root:not(:has(.eyebrow)) & {
      --offset: 1em;
    }
  }
}

.eyebrow {
  grid-area: eyebrow;
  margin-bottom: 1.6rem;
}

.titleAndDescription {
  grid-area: text;
}

.description {
  margin-top: 1.6rem;

  @media (min-width: breakpoints.$md) {
    padding-right: 4.8rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-top: 2.4rem;
    padding-right: 6.4rem;
  }
}

.quickLinks {
  grid-area: quickLinks;
  height: min-content;
  margin-top: 6.4rem;

  @media (min-width: breakpoints.$md) {
    margin-top: 0;
  }
}

.creditSection {
  grid-area: credits;

  .root:not(.portraitImage) & {
    margin-top: 4rem;

    @media (min-width: breakpoints.$md) {
      margin-top: 3.2rem;
    }

    @media (min-width: breakpoints.$lg) {
      margin-top: 4rem;
    }
  }
}

.titleAndDescription,
.creditSection {
  @media (min-width: breakpoints.$md) {
    padding-right: 4.8rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding-right: 6.4rem;
  }
}

.image,
.creditSection {
  margin-top: 4rem;

  @media (min-width: breakpoints.$md) {
    margin-top: 4.8rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-top: 6.4rem;
  }
}

.image {
  display: flex;
  grid-area: image;
  flex-direction: column;

  .caption {
    flex: 1;
  }
}

.caption {
  margin-top: 1.6rem;
  color: color.$caption;

  span {
    display: block;
    margin-inline: grid.$margin-sm;

    .portraitImage & {
      @media (min-width: breakpoints.$md) {
        margin-left: 0;
      }

      @media (min-width: breakpoints.$lg) {
        margin-right: grid.$margin-md;
      }
    }

    @media (min-width: breakpoints.$md) {
      margin-inline: grid.$margin-md;
    }

    @media (min-width: breakpoints.$lg) {
      // match right margin of rich text
      margin-inline: grid.$margin-lg 4rem;
    }
  }
}
